<template>
  <div>
    <div v-if="cargando" variant="success" class="mt-5 d-flex justify-content-center">
      <b-spinner variant="success" label="Loading..."></b-spinner>
    </div>
    <div v-if="!cargando && estadoResultados === 4 && tipoPerfil === 'usuario'"
      class="cuadro_central mb-5 justify-content-md-center">
      <div v-if="$parent.detalle == 0" class="justify-content-md-center" style="overflow:hidden ;position:relative;
        " v-bind:style="[
      window.height > 700
        ? window.height < 900 && $mq === 'mobile'
          ? { height: window.height - 220 + 'px' }
          : { height: window.height - 170 + 'px' }
        : { height: window.height - 140 + 'px' }
    ]">
        <b-row class="ml-2 mt-5 mr-2">
          <!------------------------------------------------------------------------------------------------->
          <div v-if="$mq === 'mobile' || window.height < 700">
            <b-row>
              <b-col style=" margin: -47px 0px 0px 0px; padding: 0px 0px 0px 0px; height: 30px;"></b-col>
              <b-col style="text-align: right;  margin: -47px 10px 0px 0px; padding: 0px 0px 0px 0px; height: 30px;">
                <b-button v-b-toggle.menu-lateral variant="success" z-index="10000"
                  style="width: 30px; height: 30px;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px"><i
                    class="fas fa-bars fa-xs" />
                </b-button>
              </b-col>
            </b-row>
            <b-sidebar ref="sidebar" id="menu-lateral" shadow z-index="100000" width="240px" backdrop>
              <b-col
                style="text-align: left;max-width: 250px;margin:0px 0px 0px 0px;padding:30px 50px 30px 10px;background-color:#efefef;  border-radius: 0px 20px 20px 0px;">
                <b style="padding:0px 0px 0px 15px;">{{ $t('caracteristicas') }}</b>
                <b-row class="mt-4">
                  <b-link @click="cambioFiltro('todos')" variant="outline-success" class="link-menu">
                    <b-row>
                      <b-col cols="1" class="ml-2"><img :src="getImgLogoUrl('menu_filtro_todos.png')"
                          style="width:20px;height:20px;" /></b-col>
                      <b-col style="text-align:left;">{{ $t('todos') }}</b-col>
                      <b-col style="text-align:right;margin:0px 10px 0px 0px;">
                        {{ totalRasgosInforme() }}
                      </b-col>
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2">
                  <b-link @click="
      cambioFiltro(getElementoPorNombre('Psicología').nombre)
      " variant="outline-success" class="link-menu">
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img :src="getImgLogoUrl('MB_icon_cat_psicologia.png')"
                          style="width:20px;height:20px;" /></b-col>
                      <b-col style="text-align:left;">{{
      $t('psicologia')
    }}</b-col>
                      <b-col style="text-align:right;;margin:0px 10px 0px 0px;">{{
        getElementoPorNombre('Psicología').rasgosInforme
          .length
      }}</b-col>
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2">
                  <b-link
                    @click="
                      cambioFiltro(getElementoPorNombre('Capacidades').nombre)
                    "
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_capacidades.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">prueba: {{
                        $t('capacidades')
                      }}</b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Capacidades').rasgosInforme
                            .length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2">
                  <b-link
                    @click="cambioFiltro(getElementoPorNombre('Sueño').nombre)"
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_sueño.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">{{
                        $t('sueño')
                      }}</b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Sueño').rasgosInforme.length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2">
                  <b-link
                    @click="
                      cambioFiltro(getElementoPorNombre('Adicciones').nombre)
                    "
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_adicciones.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;"
                        >{{ $t('adicciones') }}
                      </b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Adicciones').rasgosInforme
                            .length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-2 mb-5">
                  <b-link
                    @click="
                      cambioFiltro(getElementoPorNombre('Curiosidades').nombre)
                    "
                    variant="outline-success"
                    class="link-menu"
                  >
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img
                          :src="getImgLogoUrl('MB_icon_cat_curiosidades.png')"
                          style="width:20px;height:20px;"
                      /></b-col>
                      <b-col style="text-align:left;">{{
                       $t('curiosidades')
                      }}</b-col>
                      <b-col
                        style="text-align:right;margin:0px 10px 0px 0px;"
                        >{{
                          getElementoPorNombre('Curiosidades').rasgosInforme
                            .length
                        }}</b-col
                      >
                    </b-row>
                  </b-link>
                </b-row>

                <b class="mt-5" style="padding:0px 0px 0px 15px;">Opciones</b>
                <b-row class="mt-4">
                  <b-link @click="pintarPantalla()" variant="outline-success" class="link-menu">
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img :src="getImgLogoUrl('MB_icon_cat_descargar PDF.png')" style="width:20px;height:20px;" />
                      </b-col>
                      <b-col style="text-align:left;">{{ $t('descargar_pdf') }}</b-col>
                    </b-row>
                  </b-link>
                </b-row>
                <b-row class="mt-4">
                  <b-link @click="descargarFicheroSecuenciacion()" variant="outline-success" class="link-menu">
                    <b-row>
                      <b-col cols="1" class="ml-2">
                        <img :src="getImgLogoUrl('MB_icon_cat_descargar ADN RAW.png')
      " style="width:20px;height:20px;" /></b-col>

                      <b-col style="text-align:left;">{{ $t('descargar_adn_raw') }}</b-col>
                    </b-row>
                  </b-link>
                </b-row>
              </b-col>
            </b-sidebar>
          </div>
          <!------------------------------------------------------------------------------------------------->
          <b-col v-bind:style="{ height: window.height - 230 + 'px' }" class="menuCarac"
            v-if="$mq != 'mobile' && window.height > 700">
            <b style="padding:0px 0px 0px 15px;">Características</b>
            <b-row class="mt-4">
              <b-link @click="cambioFiltro('todos')" variant="outline-success" class="link-menu">
                <b-row>
                  <b-col class="ml-2" cols="1">
                    <img :src="getImgLogoUrl('menu_filtro_todos.png')" style="width:20px;height:20px;" />
                  </b-col>

                  <b-col style="text-align:left;">{{ $t('todos') }}</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">
                    {{ totalRasgosInforme() }}
                  </b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2">
              <b-link @click="cambioFiltro(getElementoPorNombre('Psicología').nombre)" variant="outline-success"
                class="link-menu">
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img :src="getImgLogoUrl('MB_icon_cat_psicologia.png')" style="width:20px;height:20px;" />
                  </b-col>
                  <b-col style="text-align:left;">{{
      $t('psicologia')
    }}</b-col>
                  <b-col style="text-align:right;;margin:0px 10px 0px 0px;">{{
        getElementoPorNombre('Psicología').rasgosInforme.length
      }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2">
              <b-link
                @click="
                  cambioFiltro(getElementoPorNombre('Capacidades').nombre)
                "
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_capacidades.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;">{{
                    $t('capacidades')
                  }}</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Capacidades').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2">
              <b-link
                @click="cambioFiltro(getElementoPorNombre('Sueño').nombre)"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_sueño.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;">{{
                    $t('sueño')
                  }}</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Sueño').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2">
              <b-link
                @click="cambioFiltro(getElementoPorNombre('Adicciones').nombre)"
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img
                      :src="getImgLogoUrl('MB_icon_cat_adicciones.png')"
                      style="width:20px;height:20px;"
                    />
                  </b-col>
                  <b-col style="text-align:left;"
                    >{{ $t('adicciones') }}
                  </b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Adicciones').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-2 mb-5">
              <b-link
                @click="
                  cambioFiltro(getElementoPorNombre('Curiosidades').nombre)
                "
                variant="outline-success"
                class="link-menu"
              >
                <b-row>
                  <b-col cols="1" class="ml-2"
                    ><img
                      :src="getImgLogoUrl('MB_icon_cat_curiosidades.png')"
                      style="width:20px;height:20px;"
                  /></b-col>
                  <b-col style="text-align:left;">{{
                    $t('curiosidades')
                  }}</b-col>
                  <b-col style="text-align:right;margin:0px 10px 0px 0px;">{{
                    getElementoPorNombre('Curiosidades').rasgosInforme.length
                  }}</b-col>
                </b-row>
              </b-link>
            </b-row>

            <b class="mt-5" style="padding:0px 0px 0px 15px;">Opciones</b>
            <b-row class="mt-4">
              <b-link @click="pintarPantallaNoMovil()" variant="outline-success" class="link-menu">
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img :src="getImgLogoUrl('MB_icon_cat_descargar PDF.png')" style="width:20px;height:20px;" />
                  </b-col>
                  <b-col style="text-align:left;">{{ $t('descargar_pdf') }}</b-col>
                </b-row>
              </b-link>
            </b-row>
            <b-row class="mt-4">
              <b-link @click="descargarFicheroSecuenciacion()" variant="outline-success" class="link-menu">
                <b-row>
                  <b-col cols="1" class="ml-2">
                    <img :src="getImgLogoUrl('MB_icon_cat_descargar ADN RAW.png')"
                      style="width:20px;height:20px;" /></b-col>
                  <b-col style="text-align:left;">{{ $t('descargar_adn_raw') }}</b-col>
                </b-row>
              </b-link>
            </b-row>
          </b-col>
          <!------------------------------------------------------------------------------------------------------------------------->
          <!------------------------------------------------------------------------------------------------------------------------->
          <!------------------------------------------------------------------------------------------------------------------------->

          <b-col>
            <div v-bind:style="[
      window.height > 700
        ? $mq === 'mobile'
          ? { height: window.height - 270 + 'px' }
          : { height: '100%' }
        : { height: window.height - 180 + 'px' }
    ]" v-bind:class="[
      window.height > 700
        ? $mq === 'mobile'
          ? 'listaRasgosP'
          : 'listaRasgosG'
        : 'listaRasgosP'
    ]">
              <div style="width:100%;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;" v-for="rasgo in listadoRasgos"
                :key="rasgo.id" no-body>
                <div style="width:90%;margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;" v-if="rasgo.nombreGrupo == filtro ||
      (filtro == 'todos' && rasgo.nombreGrupo != 'NO_GROUP')
      ">
                  <div>
                    <b-link class="link-fila" style=" max-height: 300px;" @click="rasgoActivo(rasgo)">
                      <b-row>
                        <b-col style="max-width: 40px;" class="icono-movil">
                          <img v-if="ObtenerValoracionRasgo(rasgo).score >= 2" :src="getImgLogoUrl('MB_icon_flechas informe 3 up.png')
      " style="width:40px;height:40px;" class="" />
                          <img v-if="ObtenerValoracionRasgo(rasgo).score >= 1 &&
      ObtenerValoracionRasgo(rasgo).score < 2
      " :src="getImgLogoUrl('MB_icon_flechas informe 2 up.png')
      " style="width:40px;height:40px;" />
                          <img v-if="ObtenerValoracionRasgo(rasgo).score === 0 ||
      ObtenerValoracionRasgo(rasgo).score === 'NaN'
      " :src="getImgLogoUrl('MB_icon_flechas informe igual.png')
      " style="width:40px;height:40px;" />
                          <img v-if="ObtenerValoracionRasgo(rasgo).score > 0 &&
      ObtenerValoracionRasgo(rasgo).score < 1
      " :src="getImgLogoUrl('MB_icon_flechas informe 1 up.png')
      " style="width:40px;height:40px;" />
                          <img v-if="ObtenerValoracionRasgo(rasgo).score <= -2" :src="getImgLogoUrl(
      'MB_icon_flechas informe 3 down.png'
    )
      " style="width:40px;height:40px;" />
                          <img v-if="ObtenerValoracionRasgo(rasgo).score <= -1 &&
      ObtenerValoracionRasgo(rasgo).score > -2
      " :src="getImgLogoUrl(
      'MB_icon_flechas informe 2 down.png'
    )
      " style="width:40px;height:40px;" />
                          <img v-if="ObtenerValoracionRasgo(rasgo).score < 0 &&
      ObtenerValoracionRasgo(rasgo).score > -1
      " :src="getImgLogoUrl(
      'MB_icon_flechas informe 1 down.png'
    )
      " style="width:40px;height:40px;" />
                        </b-col>

                        <b-col class="columna-izq" v-if="$mq != 'mobile' && $mq != 'tablet'">
                          <p class="texto-link">
                            {{ rasgo.cabecera }}
                          </p>
                        </b-col>
                        <b-col v-if="$mq != 'mobile' &&
      $mq != 'tablet' &&
      $mq != 'laptop'
      " style="max-width: 10%;"></b-col>
                        <b-col class="columna-der" v-if="$mq != 'mobile' && $mq != 'tablet'">
                          <p class="texto-link">
                            {{ rasgo.cabecera2 }}
                          </p>
                        </b-col>

                        <b-col v-if="$mq === 'mobile' || $mq === 'tablet'">
                          <b-row class="columna-izq-movil">
                            <p class="texto-link" style="font-weight: bold;">
                              {{ rasgo.cabecera }}
                            </p>
                          </b-row>
                          <b-row class="columna-der-movil">
                            <p class="texto-link">
                              {{ rasgo.cabecera2 }}
                            </p>
                          </b-row>
                        </b-col>

                        <b-col style="max-width: 40px; margin: 15px 0px 0px 0px;">
                          <div style="margin-top: -2px;">
                            <i class="fas fa-chevron-right fa-lg"></i>
                          </div>
                        </b-col>
                        <hr style="height:1px;max-width:94%;border-width:0;color:gray;background-color:gray" />
                      </b-row>
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="$parent.detalle == 1" class="div-detalle">
        <b-row class="row-boton-descarga-pdf">
          <button onclick="window.print()" variant="outline-success" class="btn-descarga">
            <span v-show="loading" class="spinner-border spinner-border-sm "></span>
            <span><i class="fas fa-file-download fa-1x mr-2"></i>{{ $t('descargar_pdf') }}</span>
          </button>
        </b-row>
        <div class="detalle_">
          <b-row>
            <b-col class="columna-izq-cabecera mb-4 ml-2"><img :src="getImgLogoUrl(rasgoAct.logo)"
                class="imagen-cabecera" /></b-col>
            <b-col class="mt-3 columna-der-cabecera">
              <b-row class="cabecera mt-4">{{ rasgoAct.cabecera }}</b-row>
              <b-row class="cuerpo mr-4" v-html="rasgoAct.textologo"></b-row>
            </b-col>
          </b-row>
        </div>

        <div class="cuadrotextoverde  mt-3">
          <p class="textoverde">
            {{ nombreUsuario }}, {{ rasgoAct.textoverde_a
            }}<span class="textoverdegrande"> {{ rasgoAct.textoverde_b }}</span><span class="textoverde"> {{
      rasgoAct.textoverde_c }}</span>
          </p>
        </div>
      </div>
      <div>
        <div v-show="$mq != 'mobile' && $mq != 'tablet' && $parent.detalle === 1" id="svgcontainer1"
          class="svg-principal">
          <div v-if="$parent.detalle == 1" style="text-align: center">
            <p class="cabecera mt-5">
              <center>{{ $t('tu_puntuacion') }}</center>
            </p>
            <i v-b-modal.modal-3 style="color: #747474;" class="fa fa-info-circle fa-2x"></i>
          </div>
        </div>
        <div v-show="($mq === 'mobile' || $mq === 'tablet') && $parent.detalle === 1
      " id="svgcontainer2" class="svg-principal">
          <div v-if="$parent.detalle == 1" style="text-align: center">
            <p class="cabecera mt-5">
              <center>{{ $t('tu_puntuacion') }}</center>
            </p>
            <i v-b-modal.modal-3 style="color: #747474;" class="fa fa-info-circle fa-2x"></i>
          </div>
        </div>
      </div>
      <div v-if="$parent.detalle == 1" class="div-detalle">
        <!--
        <div class="mt-5 mb-5 numero-variante justify-content-center">
          <p class="cabecera mt-5">
            <center>{{ $t('tus_variantes') }}</center>
          </p><br>-->
          <!--- MOBILE TABLE START --------------------------------------------------------------------------------------------------->
          <!--<b-row class="mt-5" v-if="$mq === 'mobile' || $mq === 'tablet'">
            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_analizadas') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#009245">
                  {{ totales }}
                </p>
              </b-row>
            </b-col>
            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_positivo') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#399A29">
                  {{ positivas }}
                </p>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="$mq === 'mobile' || $mq === 'tablet'">
            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_negativo') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#8cc63f">
                  {{ negativas }}
                </p>
              </b-row>
            </b-col>
            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_no_efecto') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#D9E021">
                  {{ sinEfecto }}
                </p>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="justify-content-center" v-if="$mq != 'mobile' && $mq != 'tablet'">
            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_analizadas') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#009245">
                  {{ totales }}
                </p>
              </b-row>
            </b-col>
            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_positivo') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#399A29">
                  {{ positivas }}
                </p>
              </b-row>
            </b-col>

            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_negativo') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#8cc63f">
                  {{ negativas }}
                </p>
              </b-row>
            </b-col>
            <b-col>
              <b-row class="fila-variante">
                <p class="texto-variantes-arriba">
                  {{ $t('variantes_no_efecto') }}
                </p>
              </b-row>
              <b-row>
                <p class="texto-variantes-abajo" style="color:#D9E021">
                  {{ sinEfecto }}
                </p>
              </b-row>
            </b-col>
          </b-row>
          <b-row class=" row-boton-variantes">
            <button v-b-modal.modal-1 variant="outline-success" class="mb-5 btn-variantes">
              <span><i class="fas fa-columns fa-1x mr-2"></i> {{ $t('ver_variantes') }}</span>
            </button>
          </b-row>
        </div>-->
        <!--- MOBILE TABLE END --------------------------------------------------------------------------------------------------->
        <p class="cabecera mt-5">
          <center>{{ $t('heredabilidad_rasgo') }}</center>
        </p>
        <div style="text-align: center;margin-left: 20rem;" class="mr-5">
          <i v-b-modal.modal-2 style="color: #747474;" class="fas fa-info-circle fa-3x"></i>
        </div>
        <div class="mb-5">
          <Progress :value="heredabilidad" :strokeWidth="40" :radius="140" :transitionDuration="2000"
            strokeColor="#399a29"><template v-slot:default>
              <div>
                <p style="font-family: 'Montserrat', sans-serif;font-size: 5rem;text-align: center;
           ">
                  {{ heredabilidad + '%' }}
                </p>
              </div>
            </template>

            <template v-slot:footer>
              <b-row>
                <b-col cols="1" class="ml-2" style="padding:0px 0px;">
                  <i class="fas fa-circle fa-2x ml-3" style="color:#3c9c2c"></i>
                </b-col>
                <b-col>
                  <p class="texto-pie-progreso ml-3">
                    {{ $t('aportacion_genetica') }}
                  </p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="1" class="ml-2" style="padding:0px 0px;">
                  <i class="fas fa-circle fa-2x ml-3" style="color:#cbe3c3"></i>
                </b-col>
                <b-col>
                  <p class="texto-pie-progreso ml-3">
                    {{ $t('aportacion_otros_factores') }}
                  </p>
                </b-col>
              </b-row>
            </template>
          </Progress>
        </div>
        <div style="text-align: center;" class="mb-5">
          <img class="imagen-separador" style="width: 80%; text-align: center; margin-left: 10px;"
            src="../images/separador.png" />
        </div>
        <div class="detalle_">
          <b-row v-if="$mq != 'mobile' && $mq != 'tablet'">
            <b-row class="cabecera mt-5 ">{{ rasgoAct.pregunta1 }}</b-row>
            <b-row>
              <b-col class="cuerpo mt-3" v-html="rasgoAct.textopregunta1"></b-col>
              <b-col class="columna_imagen_pregunta"><img :src="getImgLogoUrl(rasgoAct.imagenpregunta)"
                  width="350px" /></b-col>
            </b-row>
          </b-row>
          <b-row v-if="$mq === 'mobile' || $mq === 'tablet'">
            <b-row class="cabecera mt-5 ">{{ rasgoAct.pregunta1 }}</b-row>

            <b-row class="cuerpo mt-3" v-html="rasgoAct.textopregunta1"></b-row>
            <b-row class="mt-3 fila_imagen_pregunta"><img class="imagen_pregunta"
                :src="getImgLogoUrl(rasgoAct.imagenpregunta)" /></b-row>
          </b-row>
          <b-row class="cabecera mt-5">{{ rasgoAct.pregunta2 }} </b-row>
          <b-row class="mt-3">
            <b-col class="cuerpo mb-4" v-html="rasgoAct.textopregunta2">
            </b-col>
          </b-row>

          <!-- Factores externos-------------------------->
          <div v-if="factoresExternos[0].cabecera != '' &&
      $mq != 'mobile' &&
      $mq != 'tablet'
      ">
            <b-row>
              <p class="cabecera mt-5" v-html="rasgoAct.factoresExternosTitulo"></p>
              <p class="cuerpo  mb-4" v-html="rasgoAct.factoresExternosTexto"></p>
            </b-row>
            <br>
            <b-row class="ml-3">
              <b-col v-for="index in 2" :key="index" no-body style="width: 300px;" class="mb-4">
                <b-row v-if="factoresExternos[index - 1].texto != ''" class="mb-4">
                  <b-col style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px">
                    <img :src="getImgLogoUrl(factoresExternos[index - 1].imagen)" style="width: 150px" />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;">
                      {{ factoresExternos[index - 1].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index - 1].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-4 ml-3">
              <b-col v-for="index in 2" :key="index" no-body style="width: 300px;" class="mb-4">
                <b-row v-if="factoresExternos[index + 1].texto != ''" class="mb-4">
                  <b-col style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px">
                    <img :src="getImgLogoUrl(factoresExternos[index + 1].imagen)" style="width: 150px" />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;">
                      {{ factoresExternos[index + 1].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index + 1].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-4 ml-3">
              <b-col v-for="index in 2" :key="index" no-body style="width: 300px;" class="mb-4">
                <b-row v-if="factoresExternos[index + 3].texto != ''">
                  <b-col style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px">
                    <img :src="getImgLogoUrl(factoresExternos[index + 3].imagen)" style="width: 150px" />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;">
                      {{ factoresExternos[index + 3].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index + 3].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-4 ml-2">
              <b-col v-for="index in 2" :key="index" no-body style="width: 300px;" class="mb-4">
                <b-row v-if="factoresExternos[index + 5].texto != ''">
                  <b-col style="max-width:150px;padding:0px 0px 60px 0px;margin: 0px 0px 0px -45px">
                    <img :src="getImgLogoUrl(factoresExternos[index + 5].imagen)" style="width: 150px" />
                  </b-col>
                  <b-col>
                    <p
                      style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: left;">
                      {{ factoresExternos[index + 5].cabecera }}
                    </p>
                    <p class="cuerpo-factor">
                      {{ factoresExternos[index + 5].texto }}
                    </p>
                  </b-col>
                  <b-col v-if="index % 2 != 0" cols="2"></b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <b-row style=" text-align: center; margin:0px auto;padding:0px auto;" class="justify-content-center" v-if="factoresExternos[0].cabecera != '' &&
      ($mq === 'mobile' || $mq === 'tablet')
      ">
            <b-row>
              <p class="cabecera mt-5" v-html="rasgoAct.factoresExternosTitulo"></p>
              <p class="cuerpo  mb-4" v-html="rasgoAct.factoresExternosTexto"></p>
            </b-row>

            <b-row style=" text-align: center; margin:0px auto;padding:0px auto;" class="justify-content-center">
              <b-row v-for="index in 8" :key="index" class="justify-content-center"
                style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;">
                <b-row v-if="factoresExternos[index - 1].texto != ''"
                  style="text-align:center;margin:0px auto;padding:0px auto;" class="justify-content-center">
                  <img :src="getImgLogoUrl(factoresExternos[index - 1].imagen)" style="width: 150px" />
                </b-row>
                <b-row v-if="factoresExternos[index - 1].texto != ''" class=" mb-5"
                  style="margin:0px 0px 0px 0px;padding:0px 0px 0px 0px;">
                  <p
                    style="font-size: 100%;font-weight:bold; color: #747474;font-family: Montserrat, sans-serif;text-align: center;">
                    {{ factoresExternos[index - 1].cabecera }}
                  </p>
                  <p class="cuerpo-factor">
                    {{ factoresExternos[index - 1].texto }}
                  </p>
                </b-row>
              </b-row>
            </b-row>
          </b-row>
          <!---------------------------------------------->
          <b-row v-if="rasgoAct.howtoTitle != ''" class="cabecera mt-5" v-html="rasgoAct.howtoTitle"> </b-row>
          <b-row class="mt-3">
            <b-col v-if="rasgoAct.howtoText != ''" class="cuerpo  mb-4" v-html="rasgoAct.howtoText"> </b-col>
          </b-row>

          <!---------------------------------------------->
          <!-- Usamos la funcion getImgLogoUrl pero lo que nos devuelve es la imagen de "sabías qué" -->
          <img v-if="$mq === 'mobile' || $mq === 'tablet'" :src="getImgLogoUrl('sabiasque/' + rasgoAct.imgSabiasQue)"
            width="380px" align="center" />
          <img v-else :src="getImgLogoUrl('sabiasque/' + rasgoAct.imgSabiasQue)" width="850px" />
          <!--<b-row class="cabecera mt-5"> ¿Sabías qué? </b-row>
          <b-row class="mt-3">
            <b-col class="cuerpo  mb-4" v-html="rasgoAct.sabiasQue"> </b-col>
          </b-row>-->

          <b-row class="mt-5">
            <b-col class="cuerpo">
              <p
                style="text-align: left; font-size: 100%;font-weight:bold; color: #747474;font-family: 'Montserrat', sans-serif;">
                {{ $t('referencias') }}
              </p>
              <p class="referencias mt-1" v-html="rasgoAct.biblioPsico"></p>

              <p
                style="text-align: left; font-size: 100%;font-weight:bold; color: #747474;font-family: 'Montserrat', sans-serif;">
            
              </p>
              <p class="referencias mt-1" v-html="rasgoAct.bibliography"></p>
            </b-col>
          </b-row>
          <b-row>
            <b-col style="text-align: left; "><b-button size="sm" variant="boton_" @click="volver()"
                class="mb-4 mr-0 mt-3"><img src="../images/resultados/back.png" width="40vw" /></b-button></b-col>
          </b-row>
        </div>
      </div>

      <b-modal id="modal-2" hide-footer centered size="xl">
        <p style="font-size: 110%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;">
          {{ $t('info_titulo') }}
        </p>
        <p style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;">
          {{ $t('info_text_1') }}
        </p>
        <p style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;">
          {{ $t('info_text_2') }}
        </p>
        <p style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;">
          {{ $t('info_text_3') }}
        </p>
      </b-modal>
      <b-modal id="modal-3" hide-footer centered size="xl">
        <!-- <p
          style="font-size: 110%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;"
        >
          Puntuación Máxima Adaptativa
        </p> -->
        <p style="font-size: 110%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;">
          Puntuación de Riesgo
        </p>
        <p style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;">
          Este es el límite de puntuación obtenido en test psicológicos para
          determinar la puntuación normativa de la población en esta variable.
          Una mayor puntuación mayor de este límite implica el desarrollo poco
          habitual de la característica. Este es el límite de puntuación
          obtenido en test psicológicos para determinar la puntuación normativa
          de la población en esta variable. Una mayor puntuación mayor de este
          límite implica el desarrollo poco habitual de la característica.
        </p>
        <p style="font-size: 110%;font-weight:600; color: #747474;font-family: 'Montserrat', sans-serif;">
          Puntuación Riesgo Poligénico
        </p>
        <p style="font-size: 100%;font-weight:100; color: #747474;font-family: 'Montserrat', sans-serif;">
          Esta puntuación indica tu riesgo poligénico para el rasgo mostrado. Se
          trata de un cálculo realizado en base a las variantes genéticas que
          posees y su frecuencia en la población. Cuanto mayor es este índice
          mayor predisposición poseemos al rasgo y, cuanto menor es, menor
          predisposición poseemos. Esta puntuación indica tu riesgo poligénico
          para el rasgo mostrado. Se trata de un cálculo realizado en base a las
          variantes genéticas que posees y su frecuencia en la población. Cuanto
          mayor es este índice mayor predisposición poseemos al rasgo y, cuanto
          menor es, menor predisposición poseemos.
        </p>
      </b-modal>
      <b-modal id="modal-1" size="xl" class="modal-variantes">
        <b-row class="modal-variantes" style="font-weight: bold;">
          <b-col>RSID</b-col>
          <b-col>{{ $t('cromosoma') }}</b-col>
          <b-col>{{ $t('posicion') }}</b-col>
          <b-col>Beta</b-col>
        </b-row>
        <b-row class="modal-variantes" v-bind:style="[
      nodo.match > 0
        ? 'background-color:#feffd7'
        : 'background-color:#ffffff'
    ]" v-for="nodo in variantesResultadoActivo" :key="nodo.id" no-body>
          <b-col align-self="center">
            {{ nodo.rsid }}
          </b-col>
          <b-col align-self="center">
            {{ nodo.chr }}
          </b-col>
          <b-col align-self="center">
            {{ nodo.posicion }}
          </b-col>
          <b-col align-self="center">
            {{ nodo.valor }}
          </b-col>
        </b-row>
        <template v-slot:modal-title>
          <p style="font-weight: bold;">{{ titleModal }}</p>
        </template>
        <template v-slot:modal-footer>
          <b-col align-self="center">
            <p style="background-color:#feffd7;text-align: center;" class="modal-variantes">
              Variantes coincidentes
            </p>
          </b-col>
        </template>
      </b-modal>
    </div>

    <div v-if="$parent.detalle == 1" style="right: 0; 
        bottom: 0; 
        left: 0; 
        padding: 10px 0px 10px 0px; 
        background-color: #7bb021; 
        text-align: center;">
      <center>
        <p style="color: #ffffff">
          <a href="https://mendelbrain.com/terminos-legales/" style="color: #ffffff; font-size: 16px;">{{ $t('terms') }}</a>
          |
          <a href="https://mendelbrain.com/politica-de-privacidad/" style="color: #ffffff; font-size: 16px;">{{
      $t('privacy') }}</a>
          |
          <a href="https://mendelbrain.com/politica-de-cookies/" style="color: #ffffff; font-size: 16px;">{{
      $t('cookies') }}</a>
          |
          <a href="https://mendelbrain.com/consentimiento-informado/" style="color: #ffffff; font-size: 16px;">{{
      $t('consent') }}</a>
        </p>
        <p>
          <img width="290" class="etiqueta-ce" src="../images/MB_icono_label_footer_blanco.png" alt="Certificado CE" />
        </p>
      </center>
    </div>

    <div v-if="$parent.detalle == 0" style="position:fixed;
      right: 0; 
        bottom: 0; 
        left: 0; 
        padding: 0px 0px 0px 0px; 
        background-color: #7bb021; 
        text-align: center;">
      <center>
        <p style="color: #ffffff">
          <a href="https://mendelbrain.com/terminos-legales/" style="color: #ffffff; font-size: 16px;">{{ $t('terms') }}</a>
          |
          <a href="https://mendelbrain.com/politica-de-privacidad/" style="color: #ffffff; font-size: 16px;">{{
      $t('privacy') }}</a>
          |
          <a href="https://mendelbrain.com/politica-de-cookies/" style="color: #ffffff; font-size: 16px;">{{
      $t('cookies') }}</a>
          |
          <a href="https://mendelbrain.com/consentimiento-informado/" style="color: #ffffff; font-size: 16px;">{{
      $t('consent') }}</a>
          <img width="90" class="etiqueta-ce" src="../images/MB_icono_label_footer_blanco.png" alt="Certificado CE" />
        </p>
      </center>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Progress from 'easy-circular-progress';
import * as d3 from 'd3';

export default {
  name: 'Admin',

  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      wheight: 'red',
      listadoRasgos: [],
      cargando: false,
      nombreUsuario: '',
      codigoKit: '',
      usuario: '',
      detalle: this.$parent.detalle,
      loading: '',
      titleModal: '',
      estadoResultados: 0,
      valoracion: [],
      variantesResultadoActivo: [],
      variantesResultado: [],
      valoresResultado: [],
      heredabilidad: 0,
      positivas: 0,
      negativas: 0,
      sinEfecto: 0,
      totales: 0,
      tipoPerfil: '',
      rasgoAct: [],
      rasgos: [],
      rasgos_ingles: [],
      filtro: 'todos',
      factoresExternos: [],
      //GRAFICO
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getListadoRasgos() {
      this.listadoRasgos = [];
      for (let i = 0; i < this.rasgos.gruposResultado.length; i++) {
        var nombreGrupo = this.rasgos.gruposResultado[i].nombre;
        for (
          let j = 0;
          j < this.rasgos.gruposResultado[i].rasgosInforme.length;
          j++
        ) {
          var rasgoLista = this.rasgos.gruposResultado[i].rasgosInforme[j];
          rasgoLista.nombreGrupo = nombreGrupo;
          this.listadoRasgos.push(rasgoLista);
        }
      }

      this.listadoRasgos.sort(function (a, b) {
        return b.norden - a.norden;
      });

      //console.logthis.listadoRasgos);
    },
    getStyleMenu() {
      return {
        'background-color': '#333333'
      };
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    getEstadoResultados() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/resultado/estado',
          {
            token: user.accessToken
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.estadoResultados = response.data.estado;
            this.$parent.estadoRes = response.data.estado;
            if (this.estadoResultados == 4) {
              this.obtenerValoracion(this.$parent.language);
            } else {
              this.$router.push('/inicio');
            }
          },
          error => {
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logout');
              this.$router.push('/login');
            }
          }
        );
    },
    getTipoPerfil() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .get(
          'https://api.mendelbrain.com:55020/api/mendels/perfilusuario/usuario/' + user.id,
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.tipoPerfil = response.data;
            //console.logthis.tipoPerfil + " Resultados");
            if (this.tipoPerfil === 'usuario') {
              this.getEstadoResultados();
            }
            else {
              this.$router.push('/profesional');
            }
          },
          error => {
            console.log(error);
          }
        );
    },
    pintarPantalla() {
      this.$refs.sidebar.hide();

      setTimeout(function () {
        window.print();
      }, 2000);
    },
    pintarPantallaNoMovil() {
      window.print();
    },
    getColor(valor) {
      if (valor > 0) {
        return '#399a29';
      } else {
        return '#c6cc33';
      }
    },
    factoresExternosFiltradoMenor(pos) {
      return this.factoresExternos.filter(obj => {
        obj.posicion < pos;
      });
    },
    factoresExternosFiltradoMayor(pos) {
      return this.factoresExternos.filter(obj => {
        obj.pos > pos;
      });
    },
    getElementoPorNombre(nombre) {
      var result = this.rasgos.gruposResultado.filter(obj => {
        return obj.nombre === nombre;
      });
      if (result.length == 1) {
        return result[0];
      }
    },
    totalRasgosInforme() {
      var total = 0;
      if (Array.isArray(this.rasgos.gruposResultado)) {
        for (let i = 0; i < this.rasgos.gruposResultado.length; i++) {
          if (this.rasgos.gruposResultado[i].nombre != 'NO_GROUP') {
            total = total + this.rasgos.gruposResultado[i].rasgosInforme.length;
          }
        }
        return total;
      }
    },
    getScore(rasgo) {
      let valorRasgo = this.valoresResultado.filter(
        valorResultado => valorResultado.id_trait_master == rasgo.id_trait
      );

      if (valorRasgo[0].score <= 3 && valorRasgo[0].score >= -3) {
        return (valorRasgo[0].score * 100) / 3;
      } else if (valorRasgo[0].score < -3) {
        return -100;
      } else {
        return 100;
      }
    },
    getScoreComponente(score) {
      if (score < 0) {
        score = score * -1;
      }
      // Valor mínimo para pintar algo
      if (score < 0.3) {
        return 10;
      }
      if (score <= 3) {
        return (score * 100) / 3;
      } else if (score > 3) {
        return 100;
      } else if (score == 0) {
        return 0;
      }
    },

    descargarFicheroSecuenciacion() {
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/archivo/usuario',
          {
            token: user.accessToken
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            const url = window.URL.createObjectURL(
              new Blob([response.data.fichero])
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              response.data.name + '.' + response.data.extension
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
          },
          error => {
            console.log(error);
          }
        );
    },
    cambioFiltro(valor) {
      this.filtro = valor;
    },
    rasgoActivo(rasgo) {
      console.log("Entra" + rasgo.cabecera);
      this.rasgoAct = rasgo;

      if (this.$parent.language === 'ESP') { this.titleModal = 'Mis variantes genéticas para ' + this.rasgoAct.cabecera; }
      if (this.$parent.language === 'ENG') { this.titleModal = 'My genetic variants for ' + this.rasgoAct.cabecera; }

      this.variantesResultadoActivo = [];
      this.positivas = 0;
      this.negativas = 0;
      this.sinEfecto = 0;
      this.totales = 0;

      for (var i = 0; i < this.variantesResultado.length; i++) {
        if (
          this.variantesResultado[i].id_trait_master == this.rasgoAct.id_trait
        ) {
          this.variantesResultadoActivo.push(this.variantesResultado[i]);
        }
      }

      for (var j = 0; j < this.variantesResultadoActivo.length; j++) {
        if (this.variantesResultadoActivo[j].efecto == 1) {
          this.positivas++;
          this.totales++;
        }
        if (this.variantesResultadoActivo[j].efecto == 0) {
          this.sinEfecto++;
          this.totales++;
        }
        if (this.variantesResultadoActivo[j].efecto == -1) {
          this.negativas++;
          this.totales++;
        }
      }
      console.log("1");
      // Obtener factores Externos
      this.factoresExternos = [];
      var pos = 0;

      var cabecera = '';
      if (this.$parent.language === 'ESP') { cabecera = 'Edad' }
      if (this.$parent.language === 'ENG') { cabecera = 'Age' }

      if (this.rasgoAct.edad != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 8.png',
          texto: this.rasgoAct.edad
        });
      }


      console.log("2");

      if (this.$parent.language === 'ESP') { cabecera = 'Sucesos criticos' }
      if (this.$parent.language === 'ENG') { cabecera = 'Critical events' }

      if (this.rasgoAct.sucesosCriticos != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 4.png',
          texto: this.rasgoAct.sucesosCriticos
        });
      }

      if (this.$parent.language === 'ESP') { cabecera = 'Entorno Familiar' }
      if (this.$parent.language === 'ENG') { cabecera = 'Family environment' }
      if (this.rasgoAct.entornoFamiliar != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 1.png',
          texto: this.rasgoAct.entornoFamiliar
        });
      }

      console.log("3");

      if (this.$parent.language === 'ESP') { cabecera = 'Relaciones sociales' }
      if (this.$parent.language === 'ENG') { cabecera = 'Social relations' }
      if (this.rasgoAct.relacionesSociales != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 5.png',
          texto: this.rasgoAct.relacionesSociales
        });
      }

      if (this.$parent.language === 'ESP') { cabecera = 'Salud' }
      if (this.$parent.language === 'ENG') { cabecera = 'Health' }
      if (this.rasgoAct.salud != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 3.png',
          texto: this.rasgoAct.salud
        });
      }

      if (this.$parent.language === 'ESP') { cabecera = 'Factores Internos' }
      if (this.$parent.language === 'ENG') { cabecera = 'Internal factors' }
      if (this.rasgoAct.factoresInternos != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 6.png',
          texto: this.rasgoAct.factoresInternos
        });
      }

      if (this.$parent.language === 'ESP') { cabecera = 'Factores socio-económicos' }
      if (this.$parent.language === 'ENG') { cabecera = 'Socio-economic factors' }
      if (this.rasgoAct.factoresSocioecon != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 2.png',
          texto: this.rasgoAct.factoresSocioecon
        });
      }

      if (this.$parent.language === 'ESP') { cabecera = 'Hábitos' }
      if (this.$parent.language === 'ENG') { cabecera = 'Habits' }
      if (this.rasgoAct.habits != '') {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: cabecera,
          imagen: 'MB_icon_FE 7.png',
          texto: this.rasgoAct.habits
        });
      }
      for (let ind = this.factoresExternos.length; ind < 8; ind++) {
        pos++;
        this.factoresExternos.push({
          posicion: pos,
          cabecera: '',
          imagen: '',
          texto: ''
        });
      }


      this.heredabilidad = this.valoresResultado.filter(
        valorResultado => valorResultado.id_trait_master == rasgo.id_trait
      )[0].heredabilidad;

      this.$parent.detalle = 1;

      let valorRasgo = this.ObtenerValoracionRasgo(rasgo);
      this.InitSVG2(380, 300, 'svgcontainer2', valorRasgo);
      this.InitSVG1(800, 400, 'svgcontainer1', valorRasgo);
    },
    ObtenerValoracionRasgo(valor) {
      let valorRasgo = this.valoresResultado.filter(
        valorResultado => valorResultado.id_trait_master == valor.id_trait
      );
      return valorRasgo[0];
    },
    volver() {
      this.$parent.detalle = 0;
      this.rasgoAct = [];
      d3.select('svg').remove();
    },
    getImgUrl(tipo) {
      if (tipo == 'igual') {
        return require('../images/resultados/igual.png');
      }
      if (tipo == 'mayor') {
        return require('../images/resultados/arriba.png');
      }
      if (tipo == 'menor') {
        return require('../images/resultados/abajo.png');
      }
    },
    getImgLogoUrl(url) {

      if (url != undefined) {
        if (url != '') {
          return require('../images/resultados/' + url);
        }
      }
    },
    modalResultadoRasgo(button) {
      this.$root.$emit('bv::show::modal', this.ResRasModal.id, button);
    },
    obtenerValoracion(language) {
      this.busy = true;
      this.cargando = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/resultado',
          {
            token: user.accessToken,
            language: language
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.nombreUsuario = response.data.nombreUsuario;
            this.codigoKit = response.data.codigoKit;
            this.valoracion = response.data.valoracion;
            this.variantesResultado = response.data.variantesResultado;
            this.valoresResultado = response.data.valoresResultado;
            this.obtenerInforme();
            this.busy = false;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    obtenerInforme() {
      this.busy = true;
      let user = JSON.parse(localStorage.getItem('user'));
      axios
        .post(
          'https://api.mendelbrain.com:55020/api/mendels/informe',
          {
            valoracion: this.valoracion
          },
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken
            }
          }
        )
        .then(
          response => {
            this.rasgos = response.data;
            this.getListadoRasgos();
            this.busy = false;
            this.cargando = false;
          },
          error => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    // GRÁFICOS
    mostrarModal() {
      this.$root.$emit('bv::show::modal', 'modal-1');
    },
    InitSVG1(ancho, alto, contenedor, valor) {
      d3.select('#' + contenedor)
        .select('svg')
        .remove();
      var svg1 = d3
        .select('#' + contenedor)
        .append('svg')
        .attr('width', ancho)
        .attr('height', alto)
        .style('display', 'block')
        .style('margin', 'auto');
      var defs1 = svg1.append('defs');

      var dropShadowFilter1 = defs1
        .append('svg:filter')
        .attr('id', 'drop-shadow1')
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '130%')
        .attr('height', '130%');
      dropShadowFilter1
        .append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
      dropShadowFilter1
        .append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
      dropShadowFilter1
        .append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'the-shadow');
      dropShadowFilter1
        .append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');
      // Base
      svg1
        .append('rect')
        .attr('y', 40)
        .attr('x', 50)
        .attr('width', ancho - 100)
        .attr('height', 40)
        .attr('rx', 20)
        .style('fill', '#eceaea')
        .style('filter', 'url(#drop-shadow1)')
        .style('stroke-width', 14);

      //console.log'Score:' + valor.score);
      //console.log'Calculo:' + this.getScoreComponente(valor.score));

      var barra_x1 = ancho / 2;
      var barra_ancho =
        (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;

      var round_top_left = 0;
      var round_top_right = 0;
      var round_bottom_left = 0;
      var round_bottom_right = 0;
      var color = '';

      if (valor.score > 0) {
        round_top_left = 0;
        round_top_right = 1;
        round_bottom_left = 0;
        round_bottom_right = 1;
        color = '#8caf36';
      } else if (valor.score < 0) {
        barra_x1 =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;
        round_top_left = 1;
        round_top_right = 0;
        round_bottom_left = 1;
        round_bottom_right = 0;
        color = '#c6cc33';
      }

      /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
      svg1
        .append('path')
        .attr(
          'd',
          this.rounded_rect(
            barra_x1,
            40,
            barra_ancho,
            40,
            20,
            round_top_left,
            round_top_right,
            round_bottom_left,
            round_bottom_right
          )
        )
        .style('fill', function () {
          return color;
        });

      //Puntuacion genetica
      if (valor.score < 0) {
        svg1
          .append('image')
          .attr('x', barra_x1 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score > 0) {
        svg1
          .append('image')
          .attr('x', ancho / 2 + barra_ancho - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score == 0) {
        svg1
          .append('image')
          .attr('x', ancho / 2 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      //Linea central
      svg1
        .append('line')
        .attr('x1', ancho / 2)
        .attr('y1', 40)
        .attr('x2', ancho / 2)
        .attr('y2', 80)
        .attr('stroke', 'black')
        .style('stroke-width', 2);

      //Texto pie

      //console.logvalor);
      var valor_score = Math.round(valor.score * 1000) / 1000;

      svg1
        .append('image')
        .attr('x', ancho / 2 - 6 - 125)
        .attr('y', 150)
        .attr('width', 30)
        .attr('height', 30)
        .attr('xlink:href', function () {
          return require('../images/resultados/Triangulo arriba.png');
        });

      var text_puntuacion = '';
      if (this.$parent.language === 'ESP') { text_puntuacion = 'Tu puntuación genética' }
      if (this.$parent.language === 'ENG') { text_puntuacion = 'Your genetic score' }
      svg1
        .append('text')
        .text(text_puntuacion)
        .attr('x', ancho / 2 - 6)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'middle');

      svg1
        .append('text')
        .text(valor_score)
        .attr('x', ancho / 2 + 130)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'end');

      //Puntuación máxima adaptativa

      var maxAdap = valor.limite;
      //console.log'Valor:' + maxAdap);
      var valorMaxAdap = 0;
      if (maxAdap > 0) {
        valorMaxAdap =
          ancho / 2 +
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }
      if (maxAdap < 0) {
        valorMaxAdap =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }

      if (maxAdap != 0 && maxAdap != null) {
        svg1
          .append('image')
          .attr('x', valorMaxAdap - 15)
          .attr('y', 5)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/triangulo abajo.png');
          });

        svg1
          .append('line')
          .attr('x1', valorMaxAdap)
          .attr('y1', 38)
          .attr('x2', valorMaxAdap)
          .attr('y2', 82)
          .attr('stroke', '#c6cc33')
          .style('stroke-width', 1);

        svg1
          .append('image')
          .attr('x', ancho / 2 - 131)
          .attr('y', 200)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/triangulo abajo.png');
          });
        svg1
          .append('text')
          .text('Puntuación de riesgo')
          .attr('x', ancho / 2 + 64)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');

        svg1
          .append('text')
          .text('')
          .attr('x', ancho / 2 - 15)
          .attr('y', 240)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');
        var valor_adap = Math.round(maxAdap * 100) / 100;

        if (maxAdap > 3) {
          valor_adap = '3.0';
        }
        if (maxAdap < -3) {
          valor_adap = '-3.0';
        }
        if (valor_adap === 'NaN') {
          valor_adap = 0;
        }

        svg1
          .append('text')
          .text(valor_adap)
          .attr('x', ancho / 2 + 130)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');


        //console.log"Final");
      }
    },

    /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
    rounded_rect(x, y, w, h, r, tl, tr, bl, br) {
      var retval;
      retval = 'M' + (x + r) + ',' + y;
      retval += 'h' + (w - 2 * r);
      if (tr) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + r;
      } else {
        retval += 'h' + r;
        retval += 'v' + r;
      }
      retval += 'v' + (h - 2 * r);
      if (br) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + r;
      } else {
        retval += 'v' + r;
        retval += 'h' + -r;
      }
      retval += 'h' + (2 * r - w);
      if (bl) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + -r + ',' + -r;
      } else {
        retval += 'h' + -r;
        retval += 'v' + -r;
      }
      retval += 'v' + (2 * r - h);
      if (tl) {
        retval += 'a' + r + ',' + r + ' 0 0 1 ' + r + ',' + -r;
      } else {
        retval += 'v' + -r;
        retval += 'h' + r;
      }
      retval += 'z';
      return retval;
    },
    InitSVG2(ancho, alto, contenedor, valor) {
      //console.log"Final");
      d3.select('#' + contenedor)
        .select('svg')
        .remove();
      var svg2 = d3
        .select('#' + contenedor)
        .append('svg')
        .attr('width', ancho)
        .attr('height', alto)
        .style('display', 'block')
        .style('margin', 'auto');

      var defs = svg2.append('defs');

      var dropShadowFilter = defs
        .append('svg:filter')
        .attr('id', 'drop-shadow')
        .attr('filterUnits', 'userSpaceOnUse')
        .attr('width', '130%')
        .attr('height', '130%');
      dropShadowFilter
        .append('svg:feGaussianBlur')
        .attr('in', 'SourceGraphic')
        .attr('stdDeviation', 5)
        .attr('result', 'blur-out');
      dropShadowFilter
        .append('svg:feColorMatrix')
        .attr('in', 'blur-out')
        .attr('type', 'hueRotate')
        .attr('values', 180)
        .attr('result', 'color-out');
      dropShadowFilter
        .append('svg:feOffset')
        .attr('in', 'color-out')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'the-shadow');
      dropShadowFilter
        .append('svg:feBlend')
        .attr('in', 'SourceGraphic')
        .attr('in2', 'the-shadow')
        .attr('mode', 'normal');
      // Base
      svg2
        .append('rect')
        .attr('y', 40)
        .attr('x', 50)
        .attr('width', ancho - 100)
        .attr('height', 40)
        .attr('rx', 20)
        .style('fill', '#eceaea')
        .style('filter', 'url(#drop-shadow)')
        .style('stroke-width', 14);

      //console.log'Score:' + valor.score);
      //console.log'Calculo:' + this.getScoreComponente(valor.score));

      var barra_x1 = ancho / 2;
      var barra_ancho =
        (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;

      var round_top_left = 0;
      var round_top_right = 0;
      var round_bottom_left = 0;
      var round_bottom_right = 0;
      var color = '';

      if (valor.score > 0) {
        round_top_left = 0;
        round_top_right = 1;
        round_bottom_left = 0;
        round_bottom_right = 1;
        color = '#8caf36';
      } else if (valor.score < 0) {
        barra_x1 =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(valor.score)) / 100;
        round_top_left = 1;
        round_top_right = 0;
        round_bottom_left = 1;
        round_bottom_right = 0;
        color = '#c6cc33';
      }

      //console.log"Final");
      /*x: x-coordinate
      y: y-coordinate
      w: width
      h: height
      r: corner radius
      tl: top_left rounded?
      tr: top_right rounded?
      bl: bottom_left rounded?
      br: bottom_right rounded?*/
      svg2
        .append('path')
        .attr(
          'd',
          this.rounded_rect(
            barra_x1,
            40,
            barra_ancho,
            40,
            20,
            round_top_left,
            round_top_right,
            round_bottom_left,
            round_bottom_right
          )
        )
        .style('fill', function () {
          return color;
        });

      //Puntuacion genetica
      if (valor.score < 0) {
        svg2
          .append('image')
          .attr('x', barra_x1 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score > 0) {
        svg2
          .append('image')
          .attr('x', ancho / 2 + barra_ancho - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      if (valor.score == 0) {
        svg2
          .append('image')
          .attr('x', ancho / 2 - 15)
          .attr('y', 80)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/Triangulo arriba.png');
          });
      }
      //Linea central
      svg2
        .append('line')
        .attr('x1', ancho / 2)
        .attr('y1', 40)
        .attr('x2', ancho / 2)
        .attr('y2', 80)
        .attr('stroke', 'black')
        .style('stroke-width', 2);

      //Texto pie

      //console.logvalor);
      var valor_score = Math.round(valor.score * 1000) / 1000;

      svg2
        .append('image')
        .attr('x', ancho / 2 - 6 - 125)
        .attr('y', 150)
        .attr('width', 30)
        .attr('height', 30)
        .attr('xlink:href', function () {
          return require('../images/resultados/Triangulo arriba.png');
        });
      //console.log"Final");

      var text_puntuacion = '';
      if (this.$parent.language === 'ESP') { text_puntuacion = 'Tu puntuación genética' }
      if (this.$parent.language === 'ENG') { text_puntuacion = 'Your genetic score' }

      svg2
        .append('text')
        .text(text_puntuacion)
        .attr('x', ancho / 2 - 6)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'middle');

      svg2
        .append('text')
        .text(valor_score)
        .attr('x', ancho / 2 + 130)
        .attr('y', 170)
        .attr('fill', 'gray')
        .attr('dy', '0.35em')
        .attr('font-size', '15')
        .attr('font-weight', 350)
        .attr('font-family', 'Montserrat, sans-serif')
        .attr('text-anchor', 'end');

      //Puntuación máxima adaptativa

      var maxAdap = valor.limite;

      var valorMaxAdap = 0;
      if (maxAdap > 0) {
        valorMaxAdap =
          ancho / 2 +
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }
      if (maxAdap < 0) {
        valorMaxAdap =
          ancho / 2 -
          (((ancho - 100) / 2) * this.getScoreComponente(maxAdap)) / 100;
      }

      if (maxAdap != 0 && maxAdap != null) {
        svg2
          .append('image')
          .attr('x', valorMaxAdap - 15)
          .attr('y', 5)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/triangulo abajo.png');
          });

        svg2
          .append('line')
          .attr('x1', valorMaxAdap)
          .attr('y1', 38)
          .attr('x2', valorMaxAdap)
          .attr('y2', 82)
          .attr('stroke', '#c6cc33')
          .style('stroke-width', 1);

        svg2
          .append('image')
          .attr('x', ancho / 2 - 131)
          .attr('y', 200)
          .attr('width', 30)
          .attr('height', 30)
          .attr('xlink:href', function () {
            return require('../images/resultados/triangulo abajo.png');
          });
        svg2
          .append('text')
          .text('Puntuación de riesgo')
          .attr('x', ancho / 2 + 64)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');

        svg2
          .append('text')
          .text('')
          .attr('x', ancho / 2 - 15)
          .attr('y', 240)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');
        var valor_adap = Math.round(maxAdap * 100) / 100;

        if (maxAdap > 3) {
          valor_adap = '3.0';
        }
        if (maxAdap < -3) {
          valor_adap = '-3.0';
        }

        if (valor_adap === 'NaN') {
          valor_adap = 0;
        }

        svg2
          .append('text')
          .text(valor_adap)
          .attr('x', ancho / 2 + 130)
          .attr('y', 220)
          .attr('fill', 'gray')
          .attr('dy', '0.35em')
          .attr('font-size', '15')
          .attr('font-weight', 350)
          .attr('font-family', 'Montserrat, sans-serif')
          .attr('text-anchor', 'end');


      }
    },
    ObtenerImagen(imagen) {
      return require('../images/resultados/' + imagen);
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {

    this.$parent.detalle = 0;

    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.usuario = JSON.parse(localStorage.getItem('user'));

    this.getTipoPerfil();
  },

  components: { Progress }
};
</script>

<style scoped>
.listaRasgosP {
  overflow-y: scroll;
  width: 100%;
  margin: -10px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.listaRasgosG {
  overflow-y: scroll;
  height: 100%;
  position: absolute;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.menuCarac {
  height: auto;
  background-color: #efefef;
  text-align: left;
  max-width: 250px;
  margin: 0px 0px 0px 0px;
  padding: 30px 50px 30px 10px;

  border-radius: 0px 20px 20px 0px;
}

.frase_cab_css1 {
  width: 60%;
  margin: 0 500px;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
  border-color: #ffffff;

  color: #222222;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
}

.frase_cab_css2 {
  width: 60%;
  margin: 0 500px;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
  border-color: #ffffff;
  color: #222222;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
}

.frase_cab_css3 {
  width: 60%;
  margin: 0 250px;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
  border-color: #ffffff;
  color: #111111;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
  text-align: right;
}

.frase_cab_css4 {
  width: 48%;

  margin: 0 500px;
  /* Added */
  float: none;
  /* Added */

  border-color: #ffffff;
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 15px;
}

.frase_cab_css5 {
  width: 50%;

  margin: 0 500px;
  /* Added */
  float: none;
  /* Added */

  border-color: #ffffff;
  color: #888888;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 15px;
}

.imagen_principal {
  width: 100%;
}

.alineado-derecha {
  text-align: right;
}

.alineado-izquierda {
  text-align: right;
}

.container {
  max-width: 3000px;
  position: relative;
  background: #ffffff;
}

.cuadro_central {
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.nav-link {
  color: #aaaaaa;
  text-align: center;
}

.image-logo {
  text-align: center;
}

.nav {
  margin-top: 50px;
  width: 2000px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaaaaa;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}

.parrafo {
  margin-top: 50px;
  width: 700px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #aaaaaa;
  line-height: 1em;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
  overflow-wrap: break-word;
  font-weight: 300;
}

p {
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
}

.card {
  width: 60%;
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
  border-color: #ffffff;
}

.detalle_ {
  width: 80%;
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
  border-color: #ffffff;
}

.cabecera {
  color: #59a22e;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 40px;
  text-align: left;
}

.cuerpo {
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 18px;
  text-align: left;
}

.textocheck {
  color: #555555;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 24px;
}

.porcentaje {
  color: #59a22e;
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 33px;
  padding: 0.75rem 1.25rem;
}

.cuadrotextoverde {
  background-image: url(../images/resultados/fondo_texto_verde.png);
  background-size: 100%;
  width: 100%;
}

.textoverde {
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: white;
  padding: 0.75rem 1.25rem;
  min-height: 100%;
}

.textoverdegrande {
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  color: white;

  min-height: 100%;
}

.header h1 {
  display: inline;
}

.referencias {
  font-family: 'Myriad Pro Light';
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

.boton_ {
  color: #ffffff;
  border-color: #ffffff;
}

@media all and (orientation: portrait) {
  .principal {
    width: 1080px;
    height: 3000px;
  }

  .card {
    width: 90%;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
    border-color: #ffffff;
  }
}

/*------------------------------------------------------------------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

listado_rasgos {
  overflow-y: scroll;
  height: 70vh;
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.columna-izq {
  max-width: 300px;
  margin: 10px 0px 0px 20px;
}

.columna-der {
  max-width: 550px;
  margin: 10px 0px 0px 20px;
  text-align: right;
  padding: 0px 0px 0px 0px;
}

.columna-izq-movil {
  max-width: 300px;
  margin: 0px 0px 0px 0px;
  font-weight: bold;
  text-align: left;
}

.columna-der-movil {
  max-width: 550px;
  margin: 0px 0px 0px 0px;
  text-align: left;
  padding: 0px 0px 0px 0px;
}

.btn-menu {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 0px 0px 0px 0px;
  margin: 0px 20px 20px 20px;
  font-size: 12px;
  width: 170px;
  height: 50px;
}

.btn-menu:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}

.texto-link {
  font-family: 'Montserrat', sans-serif;
  color: #747474;
  font-size: 14px;
  margin: 0px 0px 5px 0px;
  padding: 0px 0px 0px 0px;
}

.columna_imagen_pregunta {
  max-width: 350px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 20px;
}

.imagen-cabecera {
  margin: 40px 20px 0px 0px;
  max-width: 100px;
  text-align: right;
}

.btn-descarga-secuenciacion {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 10px 0px 10px 0px;
  margin: 100px 0px 0px 0px;
  font-size: 15px;
  font-weight: bold;
  width: 400px;
}

.btn-descarga-secuenciacion:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}

.btn-descarga {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 10px 0px 10px 0px;
  margin: 0px 0px 0px 0px;
  font-size: 15px;
  font-weight: bold;
  width: 200px;
}

.btn-descarga:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}

.btn-variantes {
  font-family: 'Montserrat', sans-serif;
  color: #399a29;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: #399a29;
  border-radius: 9999px 9999px 9999px 9999px;
  padding: 10px 20px;
  margin: 30px 0px 20px 0px;
  font-size: 15px;
  font-weight: bold;
  width: 350px;
}

.btn-variantes:hover {
  border-radius: 30px 30px 30px 30px;
  border-style: solid;
  padding: 10px 20px;
  font-weight: bold;
  color: #ffffff;
  border-color: #399a29;
  background-color: #399a29;
}

.link-menu {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  border-radius: 0px 30px 30px 0px;
  border-style: solid;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 5px;
  color: #252424;
  border-color: #efefef;
  background-color: #efefef;
  text-decoration: none;
}

.link-menu:hover {
  border-radius: 0px 30px 30px 0px;
  border-style: solid;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 5px;
  color: #ffffff;
  border-color: #a5d89d;
  background-color: #a5d89d;
  text-decoration: none;
}

.modal-variantes {
  color: #747474;
  font-family: 'Montserrat', sans-serif;
  height: 2rem;
  text-align: center;
  vertical-align: middle;
}

.texto-rasgos {
  font-family: 'Montserrat', sans-serif;
  color: #747474;
  font-size: 22px;
}

.texto-pie-progreso {
  font-family: 'Montserrat', sans-serif;
  color: #747474;
  font-size: 20px;
  text-align: left;
}

.texto-variantes-arriba {
  color: #757575;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin: 0px 10px 0px 10px;
  padding: 0px 30px 0px 30px;
}

.texto-variantes-abajo {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  text-align: center;
  font-size: 90px;
  margin: 0px 10px 0px 10px;
  padding: 0px 20px 0px 20px;
}

.fila-variante {
  height: 40px;
}

.alineado-derecha {
  text-align: right;
}

.boton-flecha-derecha {
  color: #747474;
  background-color: #ffffff;
  border-color: #ffffff;
}

.link-fila {
  color: #747474;
  align-content: center;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  font-size: 10px;
}

.link-fila:hover {
  text-decoration: none;
  color: #222222;
  border-radius: 25px 25px;
  box-shadow: #747474;
  border-style: solid;
  border-color: #222222;
}

.link-fila-menu {
  color: #747474;
}

.link-fila-menu:hover {
  text-decoration: none;
}

.link-row {
  padding: 10px 0px 15px 20px;
  margin: 0px 0px 0px 200px;
  align-content: center;
}

.link-row:hover {
  text-decoration: none;
  color: #747474;
  border-radius: 30px 30px 30px 30px;
  box-shadow: #747474;
  border-color: #747474;
  box-shadow: 0px 0px 3px 0px;
  padding: 10px 0px 15px 20px;
}

.circular-progress {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #3d147e;
  display: flex;
}

.circular-progress-interior {
  text-decoration: none;
  color: #222222;
  border-radius: 2000px;
  box-shadow: #222222;
  border-style: none;
  border-color: #222222;
  box-shadow: 0px 0px 25px 0px;
  width: 45px;
  height: 45px;
  margin: 23px;
}

.row-menu {
  text-align: center;
  width: 30%;
  padding: 0px 0px 0px 0px;
  margin: 50px auto 100px auto
    /* Added */
  ;
  float: none;
  /* Added */
  border-color: #ffffff;
}

.div-detalle {
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  justify-content: center;
}

.numero-variante {
  width: 90%;
  justify-content: center;
  margin: 0px auto;
  padding: 0px auto;
}

.card-rasgos {
  width: 40%;
  padding: 0px 0px 0px 0px;
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  border-color: #ffffff;
  text-align: center;
  align-content: center;
}

.columna-rasgos {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.row-boton-descarga-pdf {
  justify-content: end;
  margin: 0px 0px 0px 0px;
  padding: 0px 40px 0px 0px;
}

.row-boton-variantes {
  justify-content: center;
  margin: 0px 0px 90px 0px;
}

.modal-dialog {
  width: 400px;
}

/*------------------------------------------------------------------------------------------------------------------------------------*/
@media all and (max-width: 450px) {
  .icono-movil {
    padding: 0px 0px 0px 10px;
  }

  .texto-variantes-abajo {
    font-size: 70px;
  }

  .col-var-arriba {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
    text-align: center;
  }

  .fila-var-abajo {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
  }

  .imagen-cabecera {
    margin: 40px 0px 0px 0px;
    max-width: 100px;
    text-align: right;
  }

  .imagen-factor-externo {
    margin: 0px 0px 0px 0px;
    width: 60px;
    text-align: left;
  }

  .card-rasgos {
    width: 100%;
    padding: 0px 0px 0px 0px;
    border-color: #ffffff;
  }

  .row-menu {
    font-size: 90%;
  }

  .columna-rasgos {
    font-size: 80%;
    width: 5rem;
  }

  .row-boton-descarga-pdf {
    justify-content: center;

    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .textoverde {
    font-size: 100%;
  }

  .textoverdegrande {
    font-size: 100%;
  }

  .columna-izq-cabecera {
    max-width: 30%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .columna-der-cabecera {
    max-width: 70%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .alineado-derecha {
    text-align: center;
  }

  .texto-variantes-arriba {
    margin: 0px 0px 0px 0px;
    padding: 0px 20px 0px 20px;
    font-size: 100%;
  }

  .link-row {
    width: 100vw;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .rasgos {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .modal-variantes {
    font-size: 60%;
  }

  .cabecera {
    max-width: 90%;
    font-size: 25px;
    text-align: left;
    margin: 0px auto;
    padding: 0px auto;
  }

  .cuerpo {
    margin: 0px auto;
    padding: 0px auto;
    text-align: justify;
    font-size: 15px;
    max-width: 90%;
  }

  .cuerpo-factor {
    margin: 0px 0px 0px 0px;
    padding: 0px 10px 0px 10px;
    text-align: justify;
    font-size: 15px;
    max-width: 100%;
  }

  .detalle_ {
    width: 90%;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
    border-color: #ffffff;
  }

  .fila_imagen_pregunta {
    justify-content: center;
    margin: 0px auto;
  }

  .numero-variante {
    font-size: 88%;
    width: 87%;
    justify-content: center;
    margin: 0px auto;
    padding: 0px auto;
  }

  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}

@media all and (min-width: 451px) and (max-width: 900px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .icono-movil {
    padding: 0px 0px 0px 10px;
  }

  .imagen_pregunta {
    width: 350px;
  }

  .texto-variantes-abajo {
    font-size: 70px;
    margin: 0px auto;
    padding: 0px auto;
  }

  .fila_imagen_pregunta {
    justify-content: center;
  }

  .col-var-arriba {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
    text-align: center;
  }

  .fila-var-abajo {
    margin: 0px 0px 0px 0px;
    max-width: 50%;
  }

  .imagen-cabecera {
    margin: 40px 0px 0px 0px;
    max-width: 100px;
    text-align: right;
  }

  .imagen-factor-externo {
    margin: 0px 0px 0px 0px;
    width: 60px;
    text-align: left;
  }

  .card-rasgos {
    width: 90%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    border-color: #ffffff;
  }

  .row-menu {
    font-size: 90%;
  }

  .columna-rasgos {
    font-size: 80%;
  }

  .row-boton-descarga-pdf {
    justify-content: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .textoverde {
    font-size: 90%;
  }

  .textoverdegrande {
    font-size: 100%;
  }

  .columna-izq-cabecera {
    max-width: 30%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .columna-der-cabecera {
    max-width: 70%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .alineado-derecha {
    text-align: center;
  }

  .texto-variantes-arriba {
    margin: 0px auto;
    padding: 0px auto;
    font-size: 90%;
  }

  .cabecera {
    max-width: 90%;
    font-size: 30px;
    text-align: left;
    margin: 0px auto;
    padding: 0px auto;
  }

  .cuerpo {
    margin: 0px auto;
    padding: 0px auto;
    text-align: justify;
    font-size: 15px;
    max-width: 90%;
  }

  .modal-variantes {
    font-size: 60%;
  }

  .columna-rasgos {
    font-size: 80%;
  }

  .link-row {
    width: 90vw;
    margin: 0px 0px 0px 0px;
    padding: 0px auto;
  }

  .rasgos {
    width: 90vw;
    justify-content: center;
    align-items: center;
  }

  .link-row:hover {
    text-decoration: none;
    color: #747474;
    border-radius: 30px 30px 30px 30px;
    box-shadow: #747474;
    border-color: #747474;
    box-shadow: 0px 0px 3px 0px;
    padding: 10px 0px 15px 20px;
  }

  .card-rasgos {
    width: 90%;
    padding: 0px 0px 0px 0px;
    border-color: #ffffff;
  }

  .detalle_ {
    width: 90%;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
    border-color: #ffffff;
  }

  .numero-variante {
    font-size: 90%;
    width: 90%;
    justify-content: center;
    margin: 0px auto;
    padding: 0px auto;
  }
}

@media all and (min-width: 451px) and (max-width: 620px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px auto;
    text-align: justify;
    font-size: 15px;
    max-width: 100%;
  }

  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}

@media all and (min-width: 620px) and (max-width: 900px) {
  .icono-movil {
    padding: 0px 0px 0px 10px;
  }

  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 25px 0px 25px;
    font-size: 15px;
    max-width: 100%;
  }

  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}

/*------------------------------------------------------------------------------------------------------------------------------------*/
@media all and (min-width: 901px) and (max-width: 1223px) {
  .icono-movil {
    padding: 0px 0px 0px 10px;
  }

  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .columna-izq {
    max-width: 230px;
    margin: 10px 0px 0px 10px;
  }

  .columna-der {
    max-width: 550px;
    margin: 10px 0px 0px 20px;
    text-align: right;
    padding: 0px 0px 0px 0px;
  }

  .texto-link {
    font-family: 'Montserrat', sans-serif;
    color: #747474;
    font-size: 12px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
    font-size: 15px;
    max-width: 100%;
    text-align: left;
  }

  .cuerpo {
    font-size: 15px;
  }

  .fila_imagen_pregunta {
    justify-content: center;
  }

  .texto-variantes-arriba {
    font-size: 90%;
  }

  .rasgos {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .texto-variantes-arriba {
    font-size: 90%;
  }

  .textoverde {
    font-size: 100%;
  }

  .textoverdegrande {
    font-size: 100%;
  }

  .card-rasgos {
    width: 60%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    border-color: #ffffff;
  }

  .row-menu {
    font-size: 90%;
  }

  .columna-rasgos {
    font-size: 80%;
    width: 13rem;
  }

  .link-row {
    width: 650px;
    margin: 0px 0px 0px 0px;
  }

  .modal-variantes {
    font-size: 70%;
  }

  .columna-izq-cabecera {
    max-width: 35%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .columna-der-cabecera {
    max-width: 65%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }
}

/*------------------------------------------------------------------------------------------------------------------------------------*/
/* Ordenadores de sobremesa y portátiles */
@media only screen and (min-width: 1224px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .card-rasgos {
    width: 80%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    border-color: #ffffff;
  }

  .columna-izq-cabecera {
    max-width: 40%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .columna-der-cabecera {
    max-width: 60%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .rasgos {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .fila_imagen_pregunta {
    justify-content: center;
  }

  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
    font-size: 17px;
    max-width: 100%;
    text-align: left;
  }
}

/* Pantallas grandes */
@media only screen and (min-width: 1824px) {
  lista-rasgos {
    overflow-y: scroll;
    height: 70vh;
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .card-rasgos {
    width: 70%;
    padding: 0px 0px 0px 0px;
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    border-color: #ffffff;
  }

  .cuerpo-factor {
    margin: 0px auto;
    padding: 0px 0px 0px 0px;
    font-size: 17px;
    max-width: 100%;
    text-align: left;
  }

  .columna-izq-cabecera {
    max-width: 40%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .columna-der-cabecera {
    max-width: 60%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    text-align: right;
  }

  .fila_imagen_pregunta {
    justify-content: center;
  }
}
</style>
